import React from 'react';
import { Col, Row } from 'react-grid-system';
import Typography from '../components/atoms/typography';
import Spacer from '../components/atoms/spacer';
import ListItem from '../components/atoms/listItem';
import DocumentTemplate from '../components/templates/document';
import styled from 'styled-components';
import { panBoxUrl } from '../constants';
import SEO from '../components/seo';

const Bold = styled(Typography)`
  font-weight: bold;
`;

const ListWrapper = styled.ul`
  list-style: none;
`;

const Italic = styled(Typography)`
  font-style: italic;
`;

const Dataskyddspolicy = () => {
  return (
    <>
      <SEO title="DATASKYDDSPOLICY" />
      <DocumentTemplate title="DATASKYDDSPOLICY">
        <Row>
          <Col md={6}>
            <Typography variant="h4">PANBOX DATASKYDDSPOLICY</Typography>
            <Spacer />
            <Typography>
              Panbox bryr sig om din integritet och ser till att dina
              personuppgifter behandlas på ett säkert, korrekt och lagligt sätt.
              Syftet med dataskyddspolicyn är att informera om hur Panbox
              behandlar dina personuppgifter. Sekretesspolicyn utgör information
              för enskilda och har utformats i enlighet med bestämmelserna i
              dataskyddsförordningen (&quot;GDPR&quot;). Om du på något sätt
              skickar personuppgifter till Panbox uppmanar vi dig att läsa denna
              policy först.
            </Typography>
            <Spacer />
            <Typography>
              Denna policy omfattar personuppgiftsbehandling av följande
              kategorier av fysiska personer: kunder, personer som kontaktar
              oss, besökare på webbplatsen{' '}
              <Typography
                color="primary"
                variant="external-link"
                href={panBoxUrl}
                target="__blank"
              >
                www.panbox.se
              </Typography>{' '}
              och kontaktpersoner hos våra leverantörer/intressenter.
            </Typography>
            <Spacer />
            <Typography variant="h4">
              1. VEM ANSVARAR FÖR DINA PERSONUPPGIFTER?
            </Typography>
            <Spacer />
            <Typography>
              Personuppgiftsansvarig för personuppgiftsbehandling som omfattas
              av denna dataskyddspolicy är PB Services AB, org.nr. 559271-7408,
              adress c/o Wendén och Rydholm, Kungsportsavenyen 21, 411 36
              Göteborg. Du är välkommen att kontakta oss när som helst om du har
              några frågor angående vår behandling av dina personuppgifter. Du
              kan skicka ett e-postmeddelande till{' '}
              <Typography
                color="primary"
                variant="external-link"
                href="mailto:info@panbox.se"
                target="__blank"
              >
                info@panbox.se
              </Typography>
              .
            </Typography>
            <Spacer />
            <Typography variant="h4">2. PERSONUPPGIFTSBEHANDLING</Typography>
            <Spacer />
            <Typography variant="h4">2.1 panBox kunder</Typography>
            <Typography>
              Varför och på vilken rättslig grund behandlar vi dina
              personuppgifter?
            </Typography>
            <Spacer />
            <Italic>
              För fullgörande av ett avtal (artikel 6.1 (b) GDPR):
            </Italic>
            <ListWrapper>
              <ListItem>
                För att hantera köpet inklusive att bekräfta din identitet och
                kontaktuppgifter, leverera produkten och tillhandahålla
                tjänsten.
              </ListItem>
              <ListItem>
                För att hantera vår kundrelation och eventuella kundfrågor
                inklusive klagomål.
              </ListItem>
              <ListItem>
                För att hantera och administrera fakturering och betalningar.
              </ListItem>
            </ListWrapper>
            <Spacer />
            <Typography>
              Om vår kund är en näringsidkare, baseras vår behandling av
              kontaktpersoners/anställdas personuppgifter i stället på vårt
              berättigade intresse (artikel 6.1 (f) i GDPR) att uppfylla och
              fullgöra förpliktelser i enlighet med avtalet.
            </Typography>
            <Spacer />
            <Italic>
              För efterlevnad av våra skyldigheter enligt lagar och förordningar
              (artikel 6.1 (c) GDPR):
            </Italic>
            <Spacer />
            <ListWrapper>
              <ListItem>
                Att lagra dokument i enlighet med lagar och regler avseende
                bokförings- och redovisningskrav.
              </ListItem>
            </ListWrapper>
            <Spacer />
            <Italic>
              För att fullfölja våra berättigade intressen (Article 6.1 (f)
              GDPR):
            </Italic>
            <Spacer />
            <ListWrapper>
              <ListItem>
                När så är tillämpligt kommer vi att behandla dina
                personuppgifter i syfte att utreda och besvara rättsliga anspråk
                såsom garantikrav samt för lagringsändamål. Vårt berättigade
                intresse är att hantera rättsliga anspråk och skydda våra
                juridiska rättigheter.
              </ListItem>
              <ListItem>
                Vi kommer att behandla dina personuppgifter i syfte att följa
                upp våra kundrelationer och för statistiska ändamål. Vårt
                berättigade intresse är att utvärdera, utveckla och förbättra
                vår verksamhet.
              </ListItem>
              <ListItem>
                Vi kommer att behandla dina personuppgifter för
                marknadsföringsändamål och för att informera dig om vår
                verksamhet. Vårt berättigade intresse är kundvård och att öka
                försäljningen.
              </ListItem>
            </ListWrapper>
            <Spacer />
            <Typography>
              Om vi skickar dig nyhetsbrev, erbjudanden och annan marknadsföring
              via e-post, är det baserat på ditt samtycke. Vi inhämtar samtycke
              i enlighet med den svenska marknadsföringslagen. Du kan när som
              helst välja bort det genom att följa instruktionerna i
              e-postmeddelandet du får från oss eller kontakta oss på{' '}
              <Typography
                color="primary"
                variant="external-link"
                href="mailto:info@panbox.se"
                target="__blank"
              >
                info@panbox.se
              </Typography>
              .
            </Typography>
            <Spacer />
            <Bold>Vilka personuppgifter behandlar vi??</Bold>
            <Spacer />
            <Typography>
              Vi behandlar under normala förhållanden namn, personnummer, adress
              och kontaktinformation, information om avtalet och köpet, tidigare
              köp, information som lämnas inom ramen för vår kommunikation med
              kunden samt fakturerings- och betalningsinformation. Om vår kund
              är ett företag behandlar vi kundens anställdas/företrädares
              kontaktuppgifter till arbete, roll/titel, namn och information som
              lämnas inom ramen för vår kommunikation med er.
            </Typography>
            <Spacer />
            <Bold>Vem har tillgång till dina personuppgifter? </Bold>
            <Spacer />
            <Typography>
              Dina personuppgifter kommer att behandlas av andra parter som
              agerar på uppdrag av Panbox (databehandlare) för att
              tillhandahålla produkten och tjänsterna, till exempel
              IT-systemleverantörer, leverantörer av e-postkonton, IT-support,
              server- och hosting-partners, lager- och logistikpartners och
              extern supportleverantör.
            </Typography>
            <Spacer />
            <Typography>
              Vi kommer också att dela dina uppgifter med myndigheter, juridiska
              rådgivare och inkassoföretag om det behövs för att hantera
              rättsliga anspråk eller för att skydda våra rättigheter.
            </Typography>
            <Spacer />
            <Typography>
              För att kunna förse dig med olika betalningslösningar delar vi
              dina uppgifter med relevant betaltjänstleverantör.
            </Typography>
            <Spacer />
            <Bold>Hur länge lagrar vi dina personuppgifter? </Bold>
            <Spacer />
            <Typography>
              Personuppgifterna lagras under kundrelationen och under en period
              av 12 månader därefter. Personuppgifter som behandlas för
              redovisningsändamål lagras i sju (7) år. Om det finns ett obetalt
              krav eller oenighet om ett köp kommer dina uppgifter att behandlas
              tills tvisten är löst.
            </Typography>
            <Spacer />
            <Typography variant="h4">2.2 Besökare på vår webbplats</Typography>
            <Spacer />
            <Bold>
              Varför och på vilken rättslig grund behandlar vi dina
              personuppgifter?
            </Bold>
            <Spacer />
            <Typography>
              Panbox webbplats och sociala digitala kanaler är
              kommunikationskanaler genom vilka vi kan tillhandahålla
              information om vår verksamhet och möjliggöra beställningar av våra
              produkter online. Kanalerna gör det också möjligt för intresserade
              att kontakta oss, anmäla sig till nyhetsbrev och evenemang.
            </Typography>
            <Spacer />
            <Typography>
              Dina personuppgifter behandlas för att möjliggöra användning av
              kommunikationskanalerna som avsett, för att hantera dina
              förfrågningar inklusive att svara dig, för att administrera och
              analysera reaktioner på
              nyhetsbrev/marknadsföring/evenemang/seminarier. Vi behandlar också
              dina personuppgifter för att analysera, mäta och följa upp
              intresset för vår verksamhet, användningen av kanalerna och för
              att identifiera nya och återkommande besökare och deras beteende
              samt för att skapa statistik om det föregående. Behandlingen
              utförs på grundval av våra berättigade intressen (artikel 6.1 (f)
              i GDPR) för att ge dig en bra användarupplevelse, ta emot
              information om besökare, tillhandahålla mer relevant innehåll,
              bedriva och utveckla vår verksamhet och för att hantera
              driftstörningar och förhindra olaglig användning av kanalerna.
            </Typography>
            <Spacer />
            <Typography>
              Vi kommer att be om ditt samtycke innan vi börjar samla in och
              bearbeta cookies för marknadsförings- och analysändamål.
            </Typography>
            <Spacer />
            <Bold>Vilka personuppgifter behandlar vi?</Bold>
            <Spacer />
            <Typography>
              Information som samlas in av inställda cookies såsom elektronisk
              identifieringsinformation, IP-adress, din webbläsartyp och
              version, information om ditt besök och hur du hittade oss, datum
              och tid för trafik/åtkomst, tidigare besökta webbplatser, sidor du
              har utforskat på vår webbplats men också den information som du
              aktivt tillhandahåller, till exempel namn och e-post. Insamlingen
              sker i samband med ditt besök på webbplatsen. I vissa fall kan vi
              identifiera vem du är genom att kombinera informationen vi samlar
              in med din e-postadress. Din egen webbläsare och
              enhetsinställningar påverkar vilken information vi kan samla in
              från ditt besök.
            </Typography>
            <Spacer />
            <Bold>Vem har tillgång till dina personuppgifter?</Bold>
            <Spacer />
            <Typography>
              Vi använder analysverktyg från Google Analytics och Facebook Pixel
              för att genomföra de affärs-, marknads- och kundanalyser som
              förklaras ovan vilket innebär att vi låter verktygen hämta
              information om ditt besök på webbplatsen. Google LLC och Facebook
              är ansvariga (personuppgiftsansvariga) för sin egen behandling av
              dina personuppgifter, besök deras webbplatser{' '}
              <Typography
                color="primary"
                variant="external-link"
                href="https://www.facebook.com/"
                target="__blank"
              >
                www.facebook.com
              </Typography>{' '}
              och{' '}
              <Typography
                color="primary"
                variant="external-link"
                href="https://www.google.com/"
                target="__blank"
              >
                www.google.com
              </Typography>{' '}
              för att läsa mer.
            </Typography>
          </Col>
          <Col md={6}>
            <Typography>
              Vi använder också sociala tilläggsprogram på webbplatsen. Det
              betyder att vi har integrerat innehåll från ett socialt nätverk på
              vår webbplats, vilket i vissa fall kan koppla din användning av
              och besök på vår webbplats till ditt konto i nätverket (förutsatt
              att du har ett konto). Innehåll från det sociala nätverket kan
              visas på vår webbplats och det är möjligt för dig att dela
              innehåll från vår webbplats på det sociala nätverket. För
              information om de sociala nätverkens personuppgiftsbehandling,
              besök deras webbplatser{' '}
              <Typography
                color="primary"
                variant="external-link"
                href="https://www.facebook.com/"
                target="__blank"
              >
                www.facebook.com
              </Typography>
              ,{' '}
              <Typography
                color="primary"
                variant="external-link"
                href="https://www.twitter.com/"
                target="__blank"
              >
                www.twitter.com
              </Typography>
              ,{' '}
              <Typography
                color="primary"
                variant="external-link"
                href="https://www.instagram.com/"
                target="__blank"
              >
                www.instagram.com
              </Typography>{' '}
              och{' '}
              <Typography
                color="primary"
                variant="external-link"
                href="https://www.google.com/"
                target="__blank"
              >
                www.google.com
              </Typography>
              .
            </Typography>
            <Spacer />
            <Typography>
              Våra leverantörer av IT-support och system-, e-postkonton och
              nyhetsbrevstjänster har också tillgång till dina personuppgifter.
              Dessa parter är databehandlare och kommer endast att behandla dina
              uppgifter för oss.
            </Typography>
            <Spacer />
            <Bold>Hur länge lagrar vi dina personuppgifter? </Bold>
            <Spacer />
            <Typography>
              Cookies lagras i enlighet med vår Cookiepolicy. Kommunikation från
              förfrågningar lagras i 6 månader.
            </Typography>
            <Spacer />
            <Typography>
              Personuppgifter rörande nyhetsbrev och annan
              marknadsföringskommunikation kommer att lagras tills du väljer
              bort det. Du kan välja bort sådan kommunikation från oss när som
              helst, oavsett om du är kund, besökare eller partner, genom att
              följa instruktionerna i e-postmeddelandet du får från oss eller
              kontakta oss på{' '}
              <Typography
                color="primary"
                variant="external-link"
                href="mailto:info@panbox.se"
                target="__blank"
              >
                info@panbox.se
              </Typography>
              .
            </Typography>
            <Spacer />
            <Typography variant="h4">
              2.3 Anställda och ställföreträdare vid våra leverantörer och
              intressenter
            </Typography>
            <Spacer />
            <Bold>
              Varför och på vilken rättslig grund behandlar vi dina
              personuppgifter?
            </Bold>
            <Typography>
              I samband med avtals-/affärsförhållandet mellan Panbox och din
              arbetsgivare behandlar vi dina personuppgifter. Detta är
              nödvändigt för att administrera avtalsförhållandet och prestera i
              enlighet med våra förpliktelser. Det är också nödvändigt för att
              Panbox ska kunna använda de tjänster eller varor som köpts på
              avsett sätt. Till exempel för att ta emot leveranser av
              produkterna, använda kundsupport, hantera fakturering eller på
              annat sätt följa upp relationen. Behandlingen baseras på vårt{' '}
              <i>berättigade intresse (artikel 6.1.f GDPR)</i> för att kunna
              administrera avtalsförhållandet, uppfylla våra avtalsförpliktelser
              och skydda våra avtalsrättigheter.
            </Typography>
            <Spacer />
            <Typography>
              I förekommande fall kan dina personuppgifter finnas på sådan
              dokumentation som vi behöver lagra för bokföringsändamål.
              Personuppgiftsbehandlingen baseras då på vårt behov av att{' '}
              <i>
                efterleva skyldigheter enligt lagar och förordningar (artikel
                6.1 (c) GDPR)
              </i>
              .
            </Typography>
            <Spacer />
            <Typography>
              I förekommande fall kan dina personuppgifter behöva behandlas för
              att vi ska kunna hävda, utreda, svara på eller försvara oss mot
              ett juridiskt krav. Till exempel i samband med en tvist med din
              arbetsgivare. Behandlingen baseras på vårt{' '}
              <i>berättigade intresse (artikel 6.1.f GDPR)</i> att hantera och
              försvara rättsliga anspråk, samt att skydda våra rättigheter
              enligt lag eller avtal.
            </Typography>
            <Spacer />
            <Bold>Vilka personuppgifter behandlar vi?</Bold>
            <Spacer />
            <Typography>
              Vi behandlar vanligtvis namn, roll/titel, arbetsgivare/klient,
              kontaktuppgifter, information som lämnas i kommunikation med oss
              (muntligt, e-post, brev) och i avtal.
            </Typography>
            <Spacer />
            <Bold>Vem har tillgång till dina personuppgifter?</Bold>
            <Spacer />
            <Typography>
              Dina personuppgifter kommer att delas med andra leverantörer som
              agerar på uppdrag av Panbox (databehandlare) såsom leverantörer av
              IT-system inklusive e-post, IT-support, server och
              hosting-partners. Vi kommer också att lämna ut dina uppgifter till
              myndigheter, revisorer, vår bank och juridiska rådgivare (som är
              personuppgiftsansvariga) om det behövs.
            </Typography>
            <Spacer />
            <Spacer />
            <Bold>Hur länge sparar vi dina personuppgifter?</Bold>
            <Spacer />
            <Typography>
              Personuppgifterna lagras så länge avtalsförhållandet mellan Panbox
              och din arbetsgivare varar och under en period av 3 år därefter.
            </Typography>
            <Spacer />
            <Typography variant="h4">
              3. VARIFRÅN SAMLAR VI DINA PERSONUPPGIFTER?
            </Typography>
            <Spacer />
            <Typography>
              <Bold variant="span">Information du lämnar till oss:</Bold> De
              flesta personuppgifter vi behandlar lämnas av dig i samband med
              ett köp eller i kommunikation via brev eller e -post.
            </Typography>
            <Spacer />
            <Typography>
              <Bold variant="span">
                Offentliga och privata informationsregister:
              </Bold>{' '}
              Vi kan kontrollera och uppdatera dina personuppgifter mot
              offentliga och privata adressregister inklusive
              folkbokföringsregister för att bekräfta din identitet och se till
              att uppgifterna är korrekta.
            </Typography>
            <Spacer />
            <Typography>
              I regel behandlas dina personuppgifter endast inom EU eller EES. I
              vissa fall kan personuppgifter lämnas ut till en part utanför EU
              eller EES. Panbox delar endast personuppgifter med företag i
              tredjeländer som har en tillräcklig skyddsnivå eller företag som
              anses ha uppnått en tillräcklig skyddsnivå genom godkända metoder.
            </Typography>
            <Spacer />
            <Typography variant="h4">
              4. DINA RÄTTIGHETER ENLIGT GDPR
            </Typography>
            <Spacer />
            <Typography>
              <Bold variant="span">Åtkomst till personuppgifter:</Bold> Du har
              rätt att få en bekräftelse på om vi behandlar dina personuppgifter
              eller inte, och om vi gör det har du rätt att begära åtkomst till
              uppgifterna jämte information om hur de behandlas.
            </Typography>
            <Spacer />
            <Typography>
              <Bold variant="span">
                Korrigering av felaktiga personuppgifter:
              </Bold>{' '}
              Du har rätt att begära rättelse av felaktiga personuppgifter.
              Dessutom har du rätt att komplettera ofullständiga
              personuppgifter.
            </Typography>
            <Spacer />
            <Typography>
              <Bold variant="span">Radering av personuppgifter:</Bold> I vissa
              fall har du rätt att begära radering av dina personuppgifter om
              hanteringen inte längre är nödvändig för det ändamål för vilket
              personuppgifterna samlades in, om det inte finns någon rättslig
              grund för behandlingen eller om vår behandling sker med grund i
              ditt samtycke.
            </Typography>
            <Spacer />
            <Typography>
              <Bold variant="span">Begränsning av behandlingen:</Bold> Du har
              rätt att begära att behandlingen av dina personuppgifter ska
              begränsas tills felaktiga uppgifter rättas eller tills en
              invändning från dig har utretts.
            </Typography>
            <Spacer />
            <Typography>
              <Bold variant="span">
                Överföring av personuppgifter/dataportabilitet:
              </Bold>{' '}
              Du har rätt att få de personuppgifter som du har lämnat till oss i
              elektronisk form och, om det är tekniskt möjligt, överföra
              uppgifterna till en annan personuppgiftsansvarig. Denna rättighet
              gäller om vi behandlar dina personuppgifter på grundval av ditt
              samtycke eller genom att fullgöra ett avtal med dig.
            </Typography>
            <Spacer />
            <Typography>
              <Bold variant="span">Återkallande av ditt samtycke:</Bold> Om vi
              behandlar dina personuppgifter på grundval av ditt samtycke kan du
              när som helst återkalla detta samtycke. Ditt återkallande påverkar
              inte lagligheten av vår behandling fram till dess att samtycket
              återkallats.
            </Typography>
            <Spacer />
            <Typography>
              <Bold variant="span">Avböjande av direktmarknadsföring:</Bold> Du
              har alltid rätt att avböja marknadsföring från oss. Kontakta oss
              för hjälp. Om du har samtyckt till elektroniska utskick kan du
              använda avregistreringslänken i e-postmeddelandet för att avböja
              framtida utskick.
            </Typography>
            <Spacer />
            <Typography>
              <Bold variant="span">Invändning mot behandlingen:</Bold> Du har
              rätt att invända mot vår behandling av dina personuppgifter för
              berättigade intressen. I sådana fall måste vi antingen bevisa att
              vi har berättigade skäl att behandla dina personuppgifter som
              väger tyngre än dina intressen eller upphöra med behandlingen av
              dina personuppgifter. Du är välkommen att kontakta oss när som
              helst för mer information om denna intresseavvägning.
            </Typography>
            <Spacer />
            <Typography>
              Vi utför inte behandling och fattar inte beslut som enbart baseras
              på automatiserad behandling, inkluderat profilering.
            </Typography>
            <Spacer />
            <Typography>
              <Bold variant="span">Klagomål på personuppgiftsbehandling:</Bold>{' '}
              Om du inte är nöjd med hur vi behandlar dina personuppgifter har
              du rätt att lämna in ett klagomål till tillsynsmyndigheten, som i
              Sverige är Integritetsskyddsmyndigheten(
              <Typography
                color="primary"
                variant="external-link"
                href="https://www.imy.se"
                target="__blank"
              >
                www.imy.se
              </Typography>
              ).
            </Typography>
            <Spacer />
            <Typography variant="h4">
              6. ÄNDRINGAR I VÅR DATASKYDDSPOLICY
            </Typography>
            <Spacer />
            <Typography>
              Panbox förbehåller sig rätten att ändra och uppdatera denna
              dataskyddspolicy. Vid väsentliga förändringar eller om befintliga
              uppgifter ska behandlas på annat sätt än det som anges kommer du
              att informeras på lämpligt sätt.
            </Typography>
          </Col>
        </Row>
      </DocumentTemplate>
    </>
  );
};

export default Dataskyddspolicy;
